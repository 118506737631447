import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import moment from "moment";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { MobileTimePicker as TimePicker } from "@mui/x-date-pickers";

import ErrorBoundary from "../../components/ErrorBoundary";
import LoyaltyConnection from "../../components/LoyaltyConnection";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";
import Switch from "../../components/Switch";
import JustEatSettings from "./Components/JustEatSettings";
import PropertyManagementSettings from "./Components/PropertyManagementSiteSettings";

import { GET_SETTINGS } from "../../helpers/apollo/utils";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify site name",
    POSHouseNumber: "Please specify POS House Number (Store)",
  },
  isNumeric: {
    POSHouseNumber:
      "Please specify a numeric POS House Number (Store) between 1 and 65535",
    resDiaryRestaurantId:
      "Please specify a numeric restaurant ID between 1 and 9999999999",
    deliverooDiscountId: "Please specify a numeric ID",
    vitaMojoDiscountId: "Please specify a numeric ID",
    POSServiceChargeId: "Please specify a numeric ID",
    networkId: "Please specify a numeric ID between 1 and 999",
    minimumOrderSurchargePlu: "Please specify a numeric PLU",
    deliveryFeePlu: "Please specify a numeric ID",
    POSVersion: "Please specify a valid POS Version ID (i.e. 6.3.500)",
  },
  other: { uberEatsStoreId: "Please specify a valid ID (UUID v4/5)" },
};

const useStyles = makeStyles(theme => ({
  form: {
    padding: 12,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  uberEatsItems: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  uberEatsItemDefault: {
    backgroundColor: "#afc5d342",
  },
  uberEatsItemActive: {
    backgroundColor: "#c0ffc0",
  },
  uberEatsItemFailed: {
    backgroundColor: "#ffc0c0",
  },
}));

// This is used to validate integer ids
const validateInt = (value, outputAs = "string", min = 1, max = null) => {
  // value is required to be passed as a string
  // outputAs can either be "string" or "number"
  if (
    value === undefined ||
    typeof value !== "string" ||
    (outputAs !== "string" && outputAs !== "number")
  ) {
    return { error: true };
  }

  const validateOptions = { allow_leading_zeroes: false };

  if (min !== null) {
    validateOptions.min = min;
  }

  if (max !== null) {
    validateOptions.max = max;
  }

  const fieldHasValue = value !== "";
  const fieldNotValid = !validator.isInt(value, validateOptions);

  if (fieldHasValue && fieldNotValid) {
    return { error: true };
  }

  let convertedValue = value;

  if (outputAs === "number") {
    convertedValue = parseInt(value, 10);
  }

  return {
    error: false,
    value: fieldHasValue ? convertedValue : "",
  };
};

const EditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const classes = useStyles();
  const [formError, setFormError] = useState("");

  const [site, setSite] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /** Name */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [uberEatsAlertOpen, setUberEatsAlertOpen] = useState(false);

  const [active, setActive] = useState(false);

  const handleActiveToggle = () => {
    setActive(!active);
  };

  const handleNameChange = event => {
    setName(event.target.value);
    setNameError("");
    setFormError("");
  };

  /** POS House Number / Store */
  const [POSHouseNumber, setPOSHouseNumber] = useState("");
  const [POSHouseNumberError, setPOSHouseNumberError] = useState("");

  const handlePOSHouseNumberChange = ({ target: { value } }) => {
    const validate = validateInt(value, "number", 1, 65535);

    if (validate.error) {
      setPOSHouseNumberError(ERROR_MESSAGES.isNumeric.POSHouseNumber);
    } else {
      setPOSHouseNumber(validate.value);
      setPOSHouseNumberError("");
    }
    setFormError("");
  };

  const [justEatDiscountId, setJustEatDiscountId] = useState("");
  const justEatDiscountIdError = null;
  const [justEatSiteId, setJustEatSiteId] = useState("");
  const justEatSiteIdError = null;

  const handleJustEatChange =
    field =>
    ({ target: { value } }) => {
      if (field === "discountId") {
        if (value === "" || value.match(/^[1-9]{1}[0-9]*$/)) {
          setJustEatDiscountId(value);
        }
      } else if (field === "siteId") {
        setJustEatSiteId(value);
      }
    };

  /** HLS integration settings */
  const [propertyManagementIntegration, setPropertyManagementIntegration] =
    useState("");

  const [hlsSftpUsername, setHlsSftpUsername] = useState("");
  const [hlsSftpPassword, setHlsSftpPassword] = useState("");
  const [hlsApiUsername, setHlsApiUsername] = useState("");
  const [hlsApiPassword, setHlsApiPassword] = useState("");
  const [hlsSiteSlug, setHlsSiteSlug] = useState("");

  const handleHlsInputChange = field => value => {
    switch (field) {
      case "hlsSftpUsername":
        setHlsSftpUsername(value);
        break;
      case "hlsSftpPassword":
        setHlsSftpPassword(value);
        break;
      case "hlsApiUsername":
        setHlsApiUsername(value);
        break;
      case "hlsApiPassword":
        setHlsApiPassword(value);
        break;
      case "hlsSiteSlug":
        setHlsSiteSlug(value);
        break;
      default:
        break;
    }
  };

  /** ResDiary restaurant ID */
  const [resDiaryRestaurantId, setResDiaryRestaurantId] = useState("");
  const [resDiaryRestaurantIdError, setResDiaryRestaurantIdError] =
    useState("");

  const handleResDiaryRestaurantIdChange = ({ target: { value } }) => {
    const minValue = 1;
    const maxValue = 9999999999;
    const outputAsNumber = "number";
    const validate = validateInt(value, outputAsNumber, minValue, maxValue);

    if (validate.error) {
      setResDiaryRestaurantIdError(
        ERROR_MESSAGES.isNumeric.resDiaryRestaurantId,
      );
    } else {
      setResDiaryRestaurantId(validate.value);
      setResDiaryRestaurantIdError("");
    }
    setFormError("");
  };

  /** ResDiary restaurant secret */
  const [resDiaryRestaurantSecret, setResDiaryRestaurantSecret] = useState("");
  const [resDiaryRestaurantSecretError, setResDiaryRestaurantSecretError] =
    useState("");

  const handleResDiaryRestaurantSecretChange = event => {
    setResDiaryRestaurantSecret(event.target.value);
    setResDiaryRestaurantSecretError("");
    setFormError("");
  };

  /** UberEats Store ID */
  const [uberEatsStores, setUberEatsStores] = useState([]);
  const [uberEatsStoreId, setUberEatsStoreId] = useState("");
  const [uberEatsStoreIdError, setUberEatsStoreIdError] = useState("");

  const handleUberEatsStoreIdChange = event => {
    setUberEatsStoreId(event.target.value);
    setUberEatsStoreIdError("");
    setFormError("");
  };

  const addUberEatsStoreId = () => {
    if (!validator.isUUID(uberEatsStoreId)) {
      setUberEatsStoreIdError(ERROR_MESSAGES.other.uberEatsStoreId);
    } else {
      setUberEatsStores([
        ...uberEatsStores,
        { uberEatsStoreId, status: "PENDING" },
      ]);
      setUberEatsStoreId("");
    }
  };

  const handleDeleteUberEatsStoreId = item => {
    setUberEatsStores(
      uberEatsStores.filter(
        store => item.uberEatsStoreId !== store.uberEatsStoreId,
      ),
    );
  };

  /** Collins venue ID */
  const [collinsVenueId, setCollinsVenueId] = useState("");
  const [collinsVenueIdError, setCollinsVenueIdError] = useState("");

  const handleCollinsVenueIdChange = event => {
    setCollinsVenueId(event.target.value);
    setCollinsVenueIdError("");
    setFormError("");
  };

  /** Minium order surcharge PLU */
  const [minimumOrderSurchargePlu, setMinimumOrderSurchargePlu] = useState("");
  const [minimumOrderSurchargePluError, setMinimumOrderSurchargePluError] =
    useState("");

  const handleMinimumOrderSurchargePluChange = event => {
    setMinimumOrderSurchargePlu(event.target.value);
    setMinimumOrderSurchargePluError("");
    setFormError("");
  };

  /** Delivery fee PLU */
  const [deliveryFeePlu, setDeliveryFeePlu] = useState("");
  const [deliveryFeePluError, setDeliveryFeePluError] = useState("");

  const handleDeliveryFeePluChange = event => {
    setDeliveryFeePlu(event.target.value);
    setDeliveryFeePluError("");
    setFormError("");
  };

  /** Deliveroo discount ID */
  const [deliverooDiscountId, setDeliverooDiscountId] = useState("");
  const [deliverooDiscountIdError, setDeliverooDiscountIdError] = useState("");

  const handleDeliverooDiscountIdChange = ({ target: { value } }) => {
    const validate = validateInt(value);

    if (validate.error) {
      setDeliverooDiscountIdError(ERROR_MESSAGES.isNumeric.deliverooDiscountId);
    } else {
      setDeliverooDiscountId(validate.value);
      setDeliverooDiscountIdError("");
    }
    setFormError("");
  };

  /** VitaMojo discount ID */
  const [vitaMojoDiscountId, setVitaMojoDiscountId] = useState("");
  const [vitaMojoDiscountIdError, setVitaMojoDiscountIdError] = useState("");

  const handleVitaMojoDiscountIdChange = ({ target: { value } }) => {
    const validate = validateInt(value);

    if (validate.error) {
      setVitaMojoDiscountIdError(ERROR_MESSAGES.isNumeric.vitaMojoDiscountId);
    } else {
      setVitaMojoDiscountId(validate.value);
      setVitaMojoDiscountIdError("");
    }
    setFormError("");
  };

  /** Preorder send time */
  const [preorderSendTime, setPreorderSendTime] = useState(
    moment("09:00", "HH:mm"),
  );

  const handlePreorderSendTimeChange = date => {
    setPreorderSendTime(date);
    setFormError("");
  };

  /** Flux store name */
  const [fluxStoreName, setFluxStoreName] = useState("");
  const [fluxStoreNameError, setFluxStoreNameError] = useState("");

  const handleFluxStoreNameChange = event => {
    setFluxStoreName(event.target.value);
    setFluxStoreNameError("");
    setFormError("");
  };

  /** POS Service Charge ID */
  const [POSServiceChargeId, setPOSServiceChargeId] = useState("");
  const [POSServiceChargeIdError, setPOSServiceChargeIdError] = useState("");

  const handlePOSServiceChargeIdChange = ({ target: { value } }) => {
    const validate = validateInt(value);

    if (validate.error) {
      setPOSServiceChargeIdError(ERROR_MESSAGES.isNumeric.POSServiceChargeId);
    } else {
      setPOSServiceChargeId(validate.value);
      setPOSServiceChargeIdError("");
    }

    setFormError("");
  };

  /** POS Version */
  const [POSVersion, setPOSVersion] = useState("");
  const [POSVersionError, setPOSVersionError] = useState("");

  const handlePOSVersionChange = event => {
    setPOSVersion(event.target.value);
    setPOSVersionError("");
    setFormError("");
  };

  /** Loyalty settings */

  const [loyaltyUrl, setLoyaltyUrl] = useState("");

  const [loyaltyUsername, setLoyaltyUsername] = useState("");

  const [loyaltyPassword, setLoyaltyPassword] = useState("");

  const [loyaltyError, setLoyaltyError] = useState(false);

  /** networkId */
  const [networkId, setNetworkId] = useState(999);
  const [networkIdError, setNetworkIdError] = useState("");

  const handleNetworkIdChange = event => {
    const newValue = parseInt(event.target.value, 10);
    if (
      Number.isNaN(newValue) ||
      !validator.isInt(event.target.value.toString(), { min: 1, max: 999 })
    ) {
      setNetworkIdError(ERROR_MESSAGES.isNumeric.networkId);
    } else {
      setNetworkId(newValue);
      setNetworkIdError("");
    }

    setFormError("");
  };

  useQuery(gql(GET_SETTINGS("propertyManagementIntegration")), {
    // This no-cache option is necessary because the settings definition doesn't return a unique
    // field. Apollo client needs a unique field to be able to track/cache data
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setPropertyManagementIntegration(
        data.settings.propertyManagementIntegration || "NULL",
      );
    },
  });

  useEffect(() => {
    if (siteId !== undefined) {
      appStore.siteStore
        .find(siteId)
        .then(result => {
          setSite(result);
          setName(result.name);
          setPOSHouseNumber(result.POSHouseNumber);
          setJustEatDiscountId(result.justEatDiscountId);
          setJustEatSiteId(result.justEatSiteId);
          setHlsSftpUsername(result.hlsSftpUsername);
          setHlsSftpPassword(result.hlsSftpPassword);
          setHlsApiUsername(result.hlsApiUsername);
          setHlsApiPassword(result.hlsApiPassword);
          setHlsSiteSlug(result.hlsSiteSlug);
          setResDiaryRestaurantId(result.resDiaryRestaurantId);
          setResDiaryRestaurantSecret(result.resDiaryRestaurantSecret ?? "");
          setUberEatsStores(result.uberEatsStores ?? []);
          setCollinsVenueId(result.collinsVenueId);
          setMinimumOrderSurchargePlu(result.minimumOrderSurchargePlu);
          setDeliveryFeePlu(result.deliveryFeePlu);
          setDeliverooDiscountId(result.deliverooDiscountId);
          setVitaMojoDiscountId(result.vitaMojoDiscountId);
          setPreorderSendTime(moment(result.preorderSendTime, "HH:mm"));
          setFluxStoreName(result.fluxStoreName ?? "");
          setPOSServiceChargeId(result.POSServiceChargeId ?? "");
          setPOSVersion(result.POSVersion);
          setNetworkId(result.networkId);
          setLoyaltyUrl(result.loyaltyUrl || "");
          setLoyaltyUsername(result.loyaltyUsername || "");
          setLoyaltyPassword(result.loyaltyPassword || "");
          setActive(result.active);

          appStore.setLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.setLoading(false);
    }
  }, [appStore.isLoading]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/sites");
  };

  const handleSubmit = event => {
    let hasError = false;
    event.preventDefault();

    if (validator.isEmpty(name.toString())) {
      hasError = true;
      setNameError(ERROR_MESSAGES.required.name);
    }

    if (validator.isEmpty(POSHouseNumber.toString())) {
      hasError = true;
      setPOSHouseNumberError(ERROR_MESSAGES.required.POSHouseNumber);
    }

    if (
      resDiaryRestaurantId !== null &&
      !validator.isEmpty(resDiaryRestaurantId.toString()) &&
      !validator.isInt(resDiaryRestaurantId.toString())
    ) {
      hasError = true;
      setResDiaryRestaurantIdError(
        ERROR_MESSAGES.isNumeric.resDiaryRestaurantId,
      );
    }

    if (
      !validator.isEmpty(minimumOrderSurchargePlu.toString()) &&
      !validator.isInt(minimumOrderSurchargePlu.toString())
    ) {
      hasError = true;
      setMinimumOrderSurchargePluError(
        ERROR_MESSAGES.isNumeric.minimumOrderSurchargePlu,
      );
    }

    if (
      !validator.isEmpty(deliveryFeePlu.toString()) &&
      !validator.isInt(deliveryFeePlu.toString())
    ) {
      hasError = true;
      setDeliveryFeePluError(ERROR_MESSAGES.isNumeric.deliveryFeePlu);
    }

    if (
      !validator.isEmpty(deliverooDiscountId.toString()) &&
      !validator.isInt(deliverooDiscountId.toString())
    ) {
      hasError = true;
      setDeliverooDiscountIdError(ERROR_MESSAGES.isNumeric.deliverooDiscountId);
    }

    if (
      !validator.isEmpty(vitaMojoDiscountId.toString()) &&
      !validator.isInt(vitaMojoDiscountId.toString())
    ) {
      hasError = true;
      setVitaMojoDiscountId(ERROR_MESSAGES.isNumeric.vitaMojoDiscountId);
    }

    if (
      !validator.isEmpty(POSVersion.toString()) &&
      !validator.matches(POSVersion.toString(), /^\d{1,2}\.\d{1,3}\.\d{1,3}$/)
    ) {
      hasError = true;
      setPOSVersionError(ERROR_MESSAGES.isNumeric.POSVersion);
    }

    if (loyaltyError) {
      hasError = true;
    }

    if (!hasError) {
      appStore.setLoading();

      if (site === null) {
        appStore.siteStore
          .add({
            name,
            POSHouseNumber,
            justEatDiscountId,
            justEatSiteId,
            hlsSftpUsername,
            hlsSftpPassword,
            hlsApiUsername,
            hlsApiPassword,
            hlsSiteSlug,
            resDiaryRestaurantId:
              resDiaryRestaurantId !== "" ? resDiaryRestaurantId : null,
            resDiaryRestaurantSecret,
            uberEatsStores,
            collinsVenueId,
            minimumOrderSurchargePlu,
            deliveryFeePlu,
            deliverooDiscountId,
            vitaMojoDiscountId,
            preorderSendTime: preorderSendTime.format("HH:mm"),
            fluxStoreName,
            POSServiceChargeId,
            POSVersion,
            loyaltyUrl,
            loyaltyUsername,
            loyaltyPassword,
            networkId,
            active,
          })
          .then(() => navigate("/app/sites"))
          .catch(error => {
            appStore.log.error(error);
            setFormError(error.message);
            appStore.setLoading(false);
          });
      } else {
        site
          .setName(name)
          .setPOSHouseNumber(POSHouseNumber)
          .setJustEatDiscountId(justEatDiscountId)
          .setJustEatSiteId(justEatSiteId)
          .setHlsSftpUsername(hlsSftpUsername)
          .setHlsSftpPassword(hlsSftpPassword)
          .setHlsApiUsername(hlsApiUsername)
          .setHlsApiPassword(hlsApiPassword)
          .setHlsSiteSlug(hlsSiteSlug)
          .setResDiaryRestaurantId(resDiaryRestaurantId || null)
          .setResDiaryRestaurantSecret(resDiaryRestaurantSecret)
          .setUberEatsStores(uberEatsStores)
          .setCollinsVenueId(collinsVenueId)
          .setMinimumOrderSurchargePlu(minimumOrderSurchargePlu)
          .setDeliveryFeePlu(deliveryFeePlu)
          .setDeliverooDiscountId(deliverooDiscountId)
          .setVitaMojoDiscountId(vitaMojoDiscountId)
          .setPreorderSendTime(preorderSendTime.format("HH:mm"))
          .setFluxStoreName(fluxStoreName)
          .setPOSServiceChargeId(POSServiceChargeId)
          .setPOSVersion(POSVersion)
          .setLoyaltyUrl(loyaltyUrl)
          .setLoyaltyUsername(loyaltyUsername)
          .setLoyaltyPassword(loyaltyPassword)
          .setNetworkId(networkId)
          .setActive(active)
          .save()
          .then(() => navigate("/app/sites"))
          .catch(error => {
            appStore.log.error(error);
            setFormError(error.message);
            appStore.setLoading(false);
          });
      }
    }
  };

  const handleVerifyUberEatsStores = e => {
    e.preventDefault();

    appStore.setLoading();
    setIsLoading(true);

    setUberEatsStores(
      uberEatsStores.map(store => ({
        ...store,
        status: "PENDING",
      })),
    );
    setUberEatsStoreId("");

    if (site === null) {
      appStore.siteStore
        .add({
          uberEatsStores,
        })
        .then(() => {
          setUberEatsAlertOpen(true);
          setIsLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          setFormError(error.message);
          appStore.setLoading(false);
          setIsLoading(false);
        });
    } else {
      site
        .setUberEatsStores(uberEatsStores)
        .saveUberEats()
        .then(() => {
          setUberEatsAlertOpen(true);
          setIsLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          setFormError(error.message);
          appStore.setLoading(false);
          setIsLoading(false);
        });
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const renderActiveStatusToggleSwitch = () => (
    <Switch
      active={active}
      onChange={handleActiveToggle}
      colour="success"
      testId="activate-site-toggle"
      label={active ? "Active" : "Inactive"}
    />
  );

  return (
    <ErrorBoundary>
      <Page title={`${site !== null ? "Edit" : "Add"} site`}>
        <Container maxWidth={false}>
          <Grid container justifyContent="flex-end">
            <Grid item>{renderActiveStatusToggleSwitch()}</Grid>
          </Grid>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {formError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{formError}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "name" }}
                      required
                      variant="standard"
                      fullWidth
                      id="siteName"
                      label="Name"
                      value={name}
                      error={!!nameError}
                      helperText={nameError}
                      onChange={handleNameChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "POSHouseNumber" }}
                      required
                      variant="standard"
                      fullWidth
                      id="POSHouseNumber"
                      label="POS House Number"
                      value={POSHouseNumber}
                      error={!!POSHouseNumberError}
                      helperText={POSHouseNumberError}
                      onChange={handlePOSHouseNumberChange}
                    />
                  </Grid>
                  <JustEatSettings
                    handleJustEatDiscountIdChange={handleJustEatChange(
                      "discountId",
                    )}
                    handleJustEatSiteIdChange={handleJustEatChange("siteId")}
                    justEatDiscountId={justEatDiscountId}
                    justEatDiscountIdError={justEatDiscountIdError}
                    justEatSiteId={justEatSiteId}
                    justEatSiteIdError={justEatSiteIdError}
                  />

                  {propertyManagementIntegration === "HLS" && (
                    <PropertyManagementSettings
                      hlsSftpUsername={hlsSftpUsername}
                      hlsSftpPassword={hlsSftpPassword}
                      hlsApiUsername={hlsApiUsername}
                      hlsApiPassword={hlsApiPassword}
                      hlsSiteSlug={hlsSiteSlug}
                      handleHlsInputChange={handleHlsInputChange}
                    />
                  )}

                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      inputProps={{ "data-testid": "ResDiaryRestaruantID" }}
                      id="ResDiaryRestaurantID"
                      fullWidth
                      label="ResDiary restaurant ID"
                      value={resDiaryRestaurantId ?? ""}
                      error={!!resDiaryRestaurantIdError}
                      helperText={resDiaryRestaurantIdError}
                      onChange={handleResDiaryRestaurantIdChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="ResDiary restaurant secret"
                      value={resDiaryRestaurantSecret}
                      error={!!resDiaryRestaurantSecretError}
                      helperText={resDiaryRestaurantSecretError}
                      id="ResDiaryRestaurantSecret"
                      onChange={handleResDiaryRestaurantSecretChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      id="CollinsVenueID"
                      label="Collins venue ID"
                      value={collinsVenueId}
                      error={!!collinsVenueIdError}
                      helperText={collinsVenueIdError}
                      onChange={handleCollinsVenueIdChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Minimum order surcharge PLU"
                      value={minimumOrderSurchargePlu}
                      error={!!minimumOrderSurchargePluError}
                      helperText={minimumOrderSurchargePluError}
                      onChange={handleMinimumOrderSurchargePluChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Delivery fee PLU"
                      value={deliveryFeePlu}
                      error={!!deliveryFeePluError}
                      helperText={deliveryFeePluError}
                      onChange={handleDeliveryFeePluChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      inputProps={{ "data-testid": "DeliverooDiscountID" }}
                      fullWidth
                      label="Deliveroo discount ID"
                      value={deliverooDiscountId}
                      error={!!deliverooDiscountIdError}
                      helperText={deliverooDiscountIdError}
                      onChange={handleDeliverooDiscountIdChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      inputProps={{ "data-testid": "VitamojoDiscountID" }}
                      fullWidth
                      label="VitaMojo discount ID"
                      value={vitaMojoDiscountId}
                      error={!!vitaMojoDiscountIdError}
                      helperText={vitaMojoDiscountIdError}
                      onChange={handleVitaMojoDiscountIdChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Flux Store Name"
                      value={fluxStoreName}
                      error={!!fluxStoreNameError}
                      helperText={fluxStoreNameError}
                      onChange={handleFluxStoreNameChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TimePicker
                      slotProps={{
                        textField: { label: "Pre-order send time" },
                      }}
                      ampm={false}
                      openTo="hours"
                      views={["hours", "minutes"]}
                      minutesStep={5}
                      format="HH:mm"
                      value={preorderSendTime}
                      onChange={handlePreorderSendTimeChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      inputProps={{ "data-testid": "POSServiceChargeID" }}
                      fullWidth
                      label="POS service charge ID"
                      value={POSServiceChargeId}
                      error={!!POSServiceChargeIdError}
                      helperText={POSServiceChargeIdError}
                      onChange={handlePOSServiceChargeIdChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="POS version"
                      value={POSVersion}
                      error={!!POSVersionError}
                      helperText={POSVersionError}
                      onChange={handlePOSVersionChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Network Id"
                      value={networkId}
                      error={!!networkIdError}
                      helperText={networkIdError}
                      onChange={handleNetworkIdChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box mt={3}>
              <LoyaltyConnection
                loyaltyUrl={loyaltyUrl}
                loyaltyPassword={loyaltyPassword}
                loyaltyUsername={loyaltyUsername}
                setLoyaltyPassword={setLoyaltyPassword}
                setLoyaltyUrl={setLoyaltyUrl}
                setLoyaltyUsername={setLoyaltyUsername}
                setFormError={setFormError}
                setLoyaltyError={setLoyaltyError}
                siteId={siteId}
              />
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="UberEats Store IDs" />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      {siteId && (
                        <PositiveAction
                          buttonText={
                            isLoading ? "In progress..." : "Verify IDs"
                          }
                          onClick={handleVerifyUberEatsStores}
                          disabled={isLoading}
                        />
                      )}
                      <Dialog
                        open={uberEatsAlertOpen}
                        onClose={() => setUberEatsAlertOpen(false)}
                        aria-labelledby="uber-eats-alert-title"
                        aria-describedby="uber-eats-alert-description"
                      >
                        <DialogTitle id="uber-eats-alert-title">
                          POS Integration Enabled with Uber Eats
                        </DialogTitle>
                        <DialogActions>
                          <NegativeAction
                            buttonText="Close"
                            onClick={() => setUberEatsAlertOpen(false)}
                          />
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.uberEatsItems}
                      aria-label="uber-eats-ids"
                    >
                      {uberEatsStores.map(item => {
                        let uberEatsItemStyle = classes.uberEatsItemDefault;
                        if (item.status === "ACTIVE") {
                          uberEatsItemStyle = classes.uberEatsItemActive;
                        } else if (item.status === "FAILED") {
                          uberEatsItemStyle = classes.uberEatsItemFailed;
                        }
                        return (
                          <Chip
                            key={item.uberEatsStoreId}
                            label={item.uberEatsStoreId}
                            onDelete={() => handleDeleteUberEatsStoreId(item)}
                            className={uberEatsItemStyle}
                          />
                        );
                      })}
                    </Grid>
                    <Grid item md={3} xs={11}>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="UberEats Store ID"
                        value={uberEatsStoreId}
                        error={!!uberEatsStoreIdError}
                        helperText={uberEatsStoreIdError}
                        onChange={handleUberEatsStoreIdChange}
                        inputProps={{
                          "aria-label": "uber-eats-store-id",
                        }}
                      />
                    </Grid>
                    <Grid item md={1} xs={1}>
                      <PositiveAction
                        buttonText="Add"
                        onClick={() => addUberEatsStoreId()}
                        testId="add-uber-eats-store-id"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Grid container py={2} justifyContent="end">
              <Grid item>
                {renderActiveStatusToggleSwitch()}
                <Box mr={1} display="inline">
                  <NegativeAction buttonText="Cancel" onClick={handleCancel} />
                </Box>
                <PositiveAction buttonText="Save" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

EditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(EditComponent));
